<template>
  <div>
    <dialog-form-list
        :config="config"
        :formData="formData"
        @cancel="cancel"
        @confirm="confirm"
        @openDialog="openDialog"
    >
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
        width="1200px"
        v-if="showDialogTableList"
        ref="dialogTableList"
        :title="dialogTitle"
        :url="dialogUrl"
        :columns="dialogColumns"
        :formData="dialogFormData"
        :config="dialogConfig"
        :defaultParams="dialogDefaultParams"
        v-model="dialogTableRow"
        @cancel="dialogCancel"
        @confirm="dialogConfirm"
        @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime) }}
      </template>
      <!-- 规则 -->
      <template v-slot:ruleType="{ row }">
        {{ $t('apiRuleTypeEnum.' + row.ruleType) }}
      </template>
      <template v-slot:headerJson="{ row }">
        <div v-for="(item,index) in JSON.parse(row.headerJson)" :key="index">
          {{item.key}} => {{item.value}}
        </div>
      </template>
    </dialog-table-list>
  </div>
</template>

<script>

import {fieldAdd, fieldUpdate, findJsonTree} from "@/api";
import {dataFormType} from "@/dict";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    let _that = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:名称
        {
          label: this.$t('platformDataManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        {
          label: this.$t('platformDataManage.mark'),
          prop: 'mark',
          rules: {
            // noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
        {
          tag: 'dialog-define',
          label: this.$t('platformDataManage.relationApi'),
          prop: 'externalApiId',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: null,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          }
        },
        {
          label: this.$t('platformDataManage.fieldName'),
          prop: 'dataName',
          rules: {
            noNull: true,
          },
        },
        {
          tag: 'el-cascader',
          // tag: 'slot-content',
          label: this.$t('platformDataManage.relationField'),
          prop: 'dataField',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: null,
            props: {
              label: 'key',
              value: 'key',
              children: "childTree"
            },
          },
        },
        {
          tag: 'el-select',
          label: this.$t('platformDataManage.fieldType'),
          prop: 'dataForm',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.dataFormType(),
          },
        },
        {
          label: this.$t('platformDataManage.fieldUnit'),
          prop: 'dataUnit',
          rules: {
            noNull: false,
          },
        },
        {
          label: this.$t('platformDataManage.fieldUnitDes'),
          prop: 'dataUnitDes',
          rules: {
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
    }
  },
  watch: {
    'formData.externalApiId'(val) {
      if(val) {
        this.findJsonTree()
      }
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.externalApiId = this.tableRow.externalApi
          this.$nextTick(() => {
            this.formData.dataField = this.tableRow.dataField.split('-')
          })
        }
      })
    },

    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择接口
      this.dialogTitle = this.$t('platformDataManage.relationApi')
      this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.apiList
      this.dialogDefaultParams = {
        scope: 'CHANNEL_LINE',
        companyUid: this.$store.getters.company.uid,
      }
      this.dialogColumns = [
        {
          label: this.$t('platformApiManage.name'),
          prop: 'name',
        },
        {
          label: this.$t('platformApiManage.ruleType'),
          prop: 'ruleType',
        },
        {
          label: this.$t('platformApiManage.url'),
          prop: 'url',
        },
        {
          label: this.$t('platformApiManage.method'),
          prop: 'netRequestType',
        },
        {
          label: this.$t('platformApiManage.header'),
          prop: 'headerJson',
        },
        {
          label: this.$t('platformApiManage.request'),
          prop: 'requestBody',
        },
        {
          label: this.$t('platformApiManage.response'),
          prop: 'responseBody',
        },
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('platformDataManage.nameOrNotes'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      formData.dataField = formData.dataField.join('-')
      formData.externalApiId = formData.externalApiId.id
      console.log(this.cmd, formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.fieldAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.fieldUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },

    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    findJsonTree() {
      this.formData.dataField = []
      this.config[4].tagProps.options = []
      const jsonStr = this.formData.externalApiId.ruleType === 'READ'?this.formData.externalApiId.responseBody:this.formData.externalApiId.requestBody
      if (typeof JSON.parse(jsonStr) === 'object') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findJsonTree,
          data: {
            idcId: this.formData.externalApiId ? this.formData.externalApiId.id : 0,
            json: jsonStr
          },
        }).then((res) => {
          let data = res.elementTrees
          // if(data.length > 0) {
          //   data.forEach(item => {
          //     if (item.childTree !== null && item.childTree.elementTrees.length > 0) {
          //       item.childTree = item.childTree.elementTrees
          //       item.childTree.forEach(item1 => {
          //         if (item1.childTree !== null && item1.childTree.elementTrees.length > 0) {
          //           item1.childTree = item1.childTree.elementTrees
          //         }
          //       })
          //     }
          //   })
          // }
          data = this.recursion(data)
          console.log('dada', data)
          this.config[4].tagProps.options = data
        })
      }
    },
    recursion(data) {
      data.map(item => {
        if (item.childTree !== null && item.childTree.elementTrees.length > 0) {
          item.childTree = item.childTree.elementTrees
          this.recursion(item.childTree)
        }
      })
      return data
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
